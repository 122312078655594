<template>
  <BaseElSelect
    v-model="syncModel"
    multiple
    value-key="type"
    placeholder="請選擇付款方式"
    no-data-text="暫無數據"
    collapse-tags
    @change="$emit('change', paymentTypes)"
  >
    <BaseElSelectOption
      v-for="item in paymentTypes"
      :key="item.type"
      :label="item.name"
      :value="item"
    />
  </BaseElSelect>
</template>

<script>
import { GetSalesPaymentTypes } from '@/api/sales'
import { mapGetters } from 'vuex'
export default {
  name: 'SalesPaymentSelect',
  props: ['model'],
  computed: {
    ...mapGetters([
      'shop',
    ]),
    syncModel: {
      get () {
        return this.model
      },
      set (data) {
        this.$emit('update:model', data)
      },
    },
  },

  data: () => ({
    paymentTypes: [],
  }),

  async mounted () {
    await this.getSalesPaymentTypes()
  },

  methods: {
    async getSalesPaymentTypes () {
      try {
        const res = await GetSalesPaymentTypes({ shopId: this.shop })
        // this.paymentTypes = res.allPaymentTypes
        this.paymentTypes = res.allPaymentTypes.filter(item => item.type !== 'cash' && item.type !== 'wallet')
      } catch (error) {
        console.log(error || error.message)
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
