import { render, staticRenderFns } from "./EditEZAIOSettingModal.vue?vue&type=template&id=add2dd70&scoped=true"
import script from "./EditEZAIOSettingModal.vue?vue&type=script&lang=js"
export * from "./EditEZAIOSettingModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "add2dd70",
  null
  
)

export default component.exports